exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-book-a-meeting-js": () => import("./../../../src/pages/book-a-meeting.js" /* webpackChunkName: "component---src-pages-book-a-meeting-js" */),
  "component---src-pages-cannabis-accounting-services-js": () => import("./../../../src/pages/cannabis-accounting-services.js" /* webpackChunkName: "component---src-pages-cannabis-accounting-services-js" */),
  "component---src-pages-cannabis-advisory-services-js": () => import("./../../../src/pages/cannabis-advisory-services.js" /* webpackChunkName: "component---src-pages-cannabis-advisory-services-js" */),
  "component---src-pages-cannabis-bookkeeping-js": () => import("./../../../src/pages/cannabis-bookkeeping.js" /* webpackChunkName: "component---src-pages-cannabis-bookkeeping-js" */),
  "component---src-pages-cannabis-cfo-js": () => import("./../../../src/pages/cannabis-cfo.js" /* webpackChunkName: "component---src-pages-cannabis-cfo-js" */),
  "component---src-pages-cannabis-industry-js": () => import("./../../../src/pages/cannabis-industry.js" /* webpackChunkName: "component---src-pages-cannabis-industry-js" */),
  "component---src-pages-cannabis-taxes-js": () => import("./../../../src/pages/cannabis-taxes.js" /* webpackChunkName: "component---src-pages-cannabis-taxes-js" */),
  "component---src-pages-client-login-js": () => import("./../../../src/pages/client-login.js" /* webpackChunkName: "component---src-pages-client-login-js" */),
  "component---src-pages-cultivators-growers-manufacturers-js": () => import("./../../../src/pages/cultivators-growers-manufacturers.js" /* webpackChunkName: "component---src-pages-cultivators-growers-manufacturers-js" */),
  "component---src-pages-dispensaries-retail-js": () => import("./../../../src/pages/dispensaries-retail.js" /* webpackChunkName: "component---src-pages-dispensaries-retail-js" */),
  "component---src-pages-get-a-quote-js": () => import("./../../../src/pages/get-a-quote.js" /* webpackChunkName: "component---src-pages-get-a-quote-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-tool-js": () => import("./../../../src/pages/pricing-tool.js" /* webpackChunkName: "component---src-pages-pricing-tool-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-section-280-e-accountants-js": () => import("./../../../src/pages/section-280e-accountants.js" /* webpackChunkName: "component---src-pages-section-280-e-accountants-js" */),
  "component---src-pages-who-we-serve-js": () => import("./../../../src/pages/who-we-serve.js" /* webpackChunkName: "component---src-pages-who-we-serve-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-cpa-jobs-js": () => import("./../../../src/templates/cpa-jobs.js" /* webpackChunkName: "component---src-templates-cpa-jobs-js" */),
  "component---src-templates-industries-js": () => import("./../../../src/templates/industries.js" /* webpackChunkName: "component---src-templates-industries-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-podcast-js": () => import("./../../../src/templates/podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */),
  "component---src-templates-specialties-js": () => import("./../../../src/templates/specialties.js" /* webpackChunkName: "component---src-templates-specialties-js" */),
  "component---src-templates-sub-service-js": () => import("./../../../src/templates/sub-service.js" /* webpackChunkName: "component---src-templates-sub-service-js" */),
  "component---src-templates-team-member-js": () => import("./../../../src/templates/team-member.js" /* webpackChunkName: "component---src-templates-team-member-js" */)
}

